// SharedValuesContext.js
import React, { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ExifReader from "exifreader";
import { useSharedValues } from "../API/SharedValuesProvider.js";
import { useRef } from "react";

const UploadSharedValuesContext = createContext();

export const UploadSharedValuesProvider = ({ children }) => {
  const { t } = useTranslation();
  const { sharedValues, refreshSession, getAllSessions } = useSharedValues();

  const [sharedUploadValues, setSharedUploadValues] = useState({
    photographer: "1",
    // Uploads is a hash map of the form:
    // {
    //   [uploadId]: {
    //     id: "",
    //     progress: 0,
    //     uploadText: "",
    //     stopUpload: () => {},
    //     stopPressed: false,
    //     uploadedFiles: 0,
    //     totalFiles: 0,
    //     finished : false
    //   }
    uploads: [],
  });
  const updateUpload = (id, id2, newValue) => {
    setSharedUploadValues((prevValues) => {
      const uploadExists = prevValues.uploads.some(
        (upload) => upload.id === id && upload.key === id2
      );

      if (uploadExists) {
        return {
          ...prevValues,
          uploads: prevValues.uploads.map((upload) =>
            upload.id === id && upload.key === id2
              ? { ...upload, ...newValue }
              : upload
          ),
        };
      } else {
        return {
          ...prevValues,
          uploads: [...prevValues.uploads, { id, ...newValue }],
        };
      }
    });
  };
  const deleteUpload = (id, key) => {
    setSharedUploadValues((prevValues) => {
      return {
        ...prevValues,
        uploads: prevValues.uploads.filter(
          (upload) => upload.id !== id || upload.key !== key
        ),
      };
    });
  };
  const updateUploadSharedValue = (key, value) => {
    setSharedUploadValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };
  const uploadCue = useRef({ uploads: [] });
  const currentlyUploading = useRef(false);

  // the key value is only set to an id, when the uploadFiles function is called from itself
  // because of the uploadcue
  const uploadFiles = async (fileList, photographer, uploadToSession, key) => {
    // import firebase from "firebase/compat/app"; but dynamically
    const FirebaseModule = await import("firebase/compat/app");
    const firebase = FirebaseModule.default;
    let uploadStoped = false;
    let uploaded = 0;
    let files;
    let uploadedFiles = 0;
    let noExifCounter = 0;
    let random = Math.floor(Math.random() * 1000000);
    try {
      if (key) {
        files = fileList;
        random = key;
      } else {
        files = Array.from(fileList);
      }
      let totalFiles = fileList.length;

      const upload = {
        id: uploadToSession,
        uploadToSession: uploadToSession,
        uploadedFiles: 0,
        totalFiles: fileList.length,
        uploadProgressAdd: 0,
        finished: false,
        waiting: false,
        key: random,
      };
      const stopUpload = () => {
        console.log("upload stoped.");
        // if waiting delete it from uploadCue
        if (upload.waiting) {
          uploadCue.current.uploads = uploadCue.current.uploads.filter(
            (upload) => upload.key !== random
          );
        }
        uploadStoped = true;
        uploadedFiles = totalFiles;
        upload.stopPressed = true;
        actualizeUploadValue(upload);
      };
      upload.stopUpload = stopUpload;

      let filesTooLarge = 0;
      let largeFiles = false;

      // Check if the file is smaller than 10MB
      for (let i = 0; i < files.length && !key; i++) {
        const file = files[i];
        if (file.size > 10000000) {
          largeFiles = true;
        }
        if (file.size > 25000000) {
          // Remove file from array
          files.splice(i, 1);
          filesTooLarge++;
          i--;
        }
      }
      if (filesTooLarge > 0) {
        toast.error(filesTooLarge + " " + t("uploadPage.file-too-large"), {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (largeFiles) {
        toast.warning(t("uploadPage.large-files"), {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      //Upload images to firebase storage
      for (
        let i = 0;
        i < files.length &&
        !key &&
        (!uploadToSession || uploadToSession === "mainPage");
        i++
      ) {
        const file = files[i];
        let tags = {};
        // Check if file has valid exif data with date before uploading
        let uploadAnyWays = false;
        try {
          tags = await ExifReader.load(file);
        } catch (error) {
          console.log("Error reading exif data: ", error);
          uploadAnyWays = true;
        }
        if (tags.DateTimeOriginal === undefined && !uploadAnyWays) {
          noExifCounter++;
          // Remove file from array
          files.splice(i, 1);
          i--;
        }
      }
      if (noExifCounter > 0) {
        toast.error(t("uploadPage.no-exif-data"), {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (files.length > 0) {
        console.log("Files with exif data: ", files);
      } else {
        deleteUpload(uploadToSession, random);
        // If no files with exif data, remove the
        console.log("No files with exif data");
        return;
      }
      // --------------------------------Here the execution stops, when another
      // upload is currently running. The upload is added to the uploadCue. So it will start after
      // the current upload is finished.
      if (currentlyUploading.current) {
        upload.waiting = true;
        actualizeUploadValue(upload);
        uploadCue.current.uploads.push({
          fileList: files,
          photographer: photographer,
          uploadToSession: uploadToSession,
          key: random,
        });
        return;
      }
      currentlyUploading.current = true;
      // Add the photographer at the end of the file name
      // So just rename the file
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = file.name;
        const fileExtension = fileName.split(".").pop();
        let newFileName =
          fileName.substring(0, fileName.length - fileExtension.length - 1) +
          "_" +
          photographer +
          "." +
          fileExtension;
        // If uploadToSession, then add the session name to the file name
        if (uploadToSession && uploadToSession !== "mainPage") {
          newFileName =
            "fixedSession_" + uploadToSession + "_fixedSession_" + newFileName;
        }
        // Create a new Blob object with the same data but a different name
        const newFile = new File([file], newFileName, { type: file.type });
        files[i] = newFile;
      }
      let uploadAttemptRef;
      let userId;
      try {
        // Create doc in uploadAttempt in firestore
        const db = firebase.firestore();
        userId = firebase.auth().currentUser?.uid;
        const fileNames = files.map((file) => {
          return file.name;
        });
        uploadAttemptRef = db
          .collection("users")
          .doc(userId)
          .collection("userStats")
          .doc("uploadAttempts")
          .collection("attempts")
          .doc(new Date().toISOString());
        uploadAttemptRef.set({
          date: new Date(),
          photographer: photographer,
          noExif: noExifCounter,
          imagesToUpload: fileNames,
          imagesSucceded: [],
          imagesFailed: [],
          allPossibleFilesUploaded: false,
          stopedByUser: false,
          filesTooLarge: filesTooLarge,
        });
      } catch (error) {
        console.log("Error creating uploadAttempt " + error);
      }
      totalFiles = files.length;
      if (files.length > 0) {
        upload.totalFiles = files.length;
        actualizeUploadValue(upload);
      }
      let uploadTaskRef;
      actualizeUploadValue(upload);
      //Upload images to firebase storage
      for (let i = 0; i < files.length && !uploadStoped; i++) {
        let retry = true;
        let retryCounter = 0;
        while (retry && retryCounter < 6) {
          retry = false;
          try {
            const file = files[i];
            // Check if file has valid exif data with date before uploading
            // import app from "../firebase"; but dynamically
            const AppModule = await import("../firebase");
            const app = AppModule.default;
            const storageRef = app.storage().ref();
            const childPath = `images/${userId}/${file.name}`;

            uploadTaskRef = storageRef.child(childPath).put(file);
            await new Promise((resolve, reject) => {
              uploadTaskRef.on(
                "state_changed",
                (snapshot) => {
                  // Update the progress bar as the upload progresses
                  if (!uploadStoped) {
                    uploaded =
                      uploadedFiles +
                      snapshot.bytesTransferred / snapshot.totalBytes;
                    upload.uploadedFiles = uploaded;
                    actualizeUploadValue(upload);
                  } else {
                    currentlyUploading.current = false;
                    uploadTaskRef.cancel();
                    reject();
                  }
                },
                (error) => {
                  console.log("Error uploading file: ", error);
                  try {
                    // Update the uploadAttempt in firestore
                    // Add the file name to the failedImages array
                    uploadAttemptRef.update({
                      imagesFailed: firebase.firestore.FieldValue.arrayUnion(
                        file.name
                      ),
                    });
                  } catch (error) {
                    console.log("Error updating uploadAttempt " + error);
                  }
                  resolve();
                },
                () => {
                  resolve();
                  try {
                    // Update the uploadAttempt in firestore
                    // Add the file name to the uploadedImages array
                    uploadAttemptRef.update({
                      imagesSucceded: firebase.firestore.FieldValue.arrayUnion(
                        file.name
                      ),
                    });
                    if (uploadedFiles + 1 === files.length) {
                      // If all files have been uploaded, update the uploadAttempt in firestore
                      uploadAttemptRef.update({
                        allPossibleFilesUploaded: true,
                      });
                    }
                  } catch (error) {
                    console.log("Error updating uploadAttempt " + error);
                  }
                  uploadedFiles++;
                  uploaded = uploadedFiles;
                  upload.uploadedFiles = uploadedFiles;
                  actualizeUploadValue(upload);
                  retry = false;
                  console.log("File uploaded successfully");
                }
              );
            });
          } catch (error) {
            console.log("Error uploading file: ", error);
            retryCounter++;
            if (retryCounter < 5) {
              retry = true;
            } else {
              uploadedFiles++;
              uploaded = uploadedFiles;
              upload.uploadedFiles = uploadedFiles;
              actualizeUploadValue(upload);
            }
          }
        }
      }
      currentlyUploading.current = false;
      if (uploadCue.current.uploads.length > 0) {
        const nextUpload = uploadCue.current.uploads.shift();
        uploadFiles(
          nextUpload.fileList,
          nextUpload.photographer,
          nextUpload.uploadToSession,
          nextUpload.key
        );
      }
      actualizeUploadValue(upload);
      startTimer(8000, function () {
        upload.uploadProgressAdd = 1;
        actualizeUploadValue(upload);
      });
      startTimer(20000, function () {
        upload.uploadProgressAdd = 2;
        actualizeUploadValue(upload);
      });
      startTimer(25000, function () {
        upload.uploadProgressAdd = 3;
        actualizeUploadValue(upload);
      });
      startTimer(40000, function () {
        upload.uploadProgressAdd = 4;
        actualizeUploadValue(upload);
      });
      startTimer(45000, function () {
        upload.uploadProgressAdd = 4.5;
        actualizeUploadValue(upload);
      });
      startTimer(50000, function () {
        upload.uploadProgressAdd = 5;
        actualizeUploadValue(upload);
      });
      startTimer(62000, function () {
        upload.uploadProgressAdd = 6;
        actualizeUploadValue(upload);
      });
      startTimer(70000, function () {
        upload.uploadProgressAdd = 6.5;
        actualizeUploadValue(upload);
      });
      startTimer(72000, function () {
        upload.uploadProgressAdd = 7;
        actualizeUploadValue(upload);
      });
      startTimer(75000, function () {
        upload.uploadProgressAdd = 7.5;
        actualizeUploadValue(upload);
      });
      startTimer(80000, function () {
        upload.finished = true;
        actualizeUploadValue(upload);
      });
      async function waitTillReturn() {
        await new Promise(resolve => {
          startTimer(85000, function () {
            deleteUpload(uploadToSession, random);
            handleUploadFinished(upload);
            resolve();
            return;
          });
        });
      }
      await waitTillReturn();
      return;
    } catch (error) {
      console.log("Error uploading files: ", error);
      currentlyUploading.current = false;
      try {
        deleteUpload(uploadToSession, random);
      } catch (error) {
        console.log("Error deleting upload: ", error);
      }
      return;
    }
  };
  const handleUploadFinished = (upload) => {
    if (upload.uploadToSession !== "mainPage") {
      refreshSession(upload.uploadToSession, true);
    } else {
      getAllSessions(sharedValues.uploadViewPageSelectedPhotographer, true);
    }
  }
  const actualizeUploadValue = (upload) => {
    upload.progress =
      5 +
      (upload.uploadedFiles / upload.totalFiles) * 100 * 0.65 +
      upload.uploadProgressAdd * 3;
    if (upload.stopPressed) {
      deleteUpload(upload.id, upload.key);
      return;
    }
    upload.uploadText =
      upload.uploadedFiles < upload.totalFiles && !upload.stopPressed
        ? t("uploadPage.uploading-files") +
          " " +
          parseInt(upload.uploadedFiles) +
          "/" +
          upload.totalFiles
        :           parseInt(upload.uploadedFiles) + " " +
        t("uploadPage.processing-files");
    if (upload.finished) {
      upload.progress = 100;
      upload.uploadText = t("uploadPage.upload-finished");
    }
    if (upload.waiting) {
      upload.progress = 0;
      upload.uploadText =  upload.totalFiles + " " + t("uploadPage.waiting");
    }
    updateUpload(upload.id, upload.key, upload);
  };
  function startTimer(duration, callback) {
    setTimeout(callback, duration);
  }
  const contextValue = {
    sharedUploadValues,
    updateUploadSharedValue,
    updateUpload,
    deleteUpload,
    uploadFiles,
  };

  return (
    <UploadSharedValuesContext.Provider value={contextValue}>
      {children}
    </UploadSharedValuesContext.Provider>
  );
};

export const useUploadSharedValues = () => {
  return useContext(UploadSharedValuesContext);
};
