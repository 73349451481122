import React, { useContext, useState, useEffect, lazy } from "react";
import { Cookies } from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { Suspense } from "react";
const CookieBanner = lazy(() => import("../MyCookieBanner"));

const AuthContext = React.createContext();
const REACT_APP_STANDARD_PAYMENT_PROVIDER =
  process.env.REACT_APP_STANDARD_PAYMENT_PROVIDER;
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  let firebase;
  let storage;
  let app;

  const getFirebase = async () => {
    if (!firebase) {
      const FirebaseModule = await import("firebase/compat/app");
      const StorageModule = await import("firebase/compat/storage");
      const AppModule = await import("../../firebase");

      firebase = FirebaseModule.default;
      storage = StorageModule.default;
      app = AppModule;
    }
    return { app, firebase, storage };
  };
  async function signup(email, password) {
    getFirebase().then(async ({ app }) => {
      console.log("signup");
      const initializeFirebase = app.initializeFirebase;
      const getFirebaseAuth = app.getFirebaseAuth;

      Cookies.set("cookie_consent", true, { expires: 365 });
      initializeFirebase();
      const user = await getFirebaseAuth().createUserWithEmailAndPassword(
        email,
        password
      );
      getFirebaseAuth().onAuthStateChanged((user) => {
        setCurrentUser(user);
        setLoading(false);
      });
      import("../../API/APICallManager")
        .then((module) => {
          const m = module.default;
          m.createUser(user.user, REACT_APP_STANDARD_PAYMENT_PROVIDER);
        })
        .catch((error) => {
          console.error("Error loading APICallManager:", error);
        });
      return;
    });
  }

  async function login(email, password) {
    getFirebase().then(async ({ app, firebase }) => {
      const initializeFirebase = app.initializeFirebase;
      const getFirebaseAuth = app.getFirebaseAuth;
      Cookies.set("cookie_consent", true, { expires: 365 });
      initializeFirebase();
      const m = getFirebaseAuth().signInWithEmailAndPassword(email, password);
      getFirebaseAuth().onAuthStateChanged((user) => {
        setCurrentUser(user);
        setLoading(false);
      });
      return m;
    });
  }

  async function logout() {
    getFirebase().then(async ({ app }) => {
      const getFirebaseAuth = app.getFirebaseAuth;
      return getFirebaseAuth().signOut();
    });
  }

  async function resetPassword(email) {
    getFirebase().then(async ({ app }) => {
      const getFirebaseAuth = app.getFirebaseAuth;
      return getFirebaseAuth().sendPasswordResetEmail(email);
    });
  }

  function updateEmail(email) {
    return currentUser?.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser?.updatePassword(password);
  }
  async function checkForOnboarded() {
    if (Cookies.get("cookie_consent")) {
      await getFirebase().then(async ({ app, firebase }) => {
        const initializeFirebase = app.initializeFirebase;
        const getFirebaseAuth = app.getFirebaseAuth;
        initializeFirebase();
        await getFirebaseAuth().onAuthStateChanged(async (user) => {
          setCurrentUser(user);
          setLoading(false);
          // Check if platform tag in the user doc is paypal or stripe
          // 1. Get the user doc
          // 2. Check the platform tag
          if (!user) {
            setLoading(false);
            return;
          }
          const userRef = await firebase
            .firestore()
            .collection("users")
            .doc(user.uid)
            .get();
          if (userRef.exists) {
            const userData = userRef.data();
            if (userData.platform === "paypal") {
              import("../../API/PayPalAPICallManager")
                .then((module) => {
                  const PayPalAPICallManager = module.default;
                  PayPalAPICallManager.checkPayPalOnboardedSuccessfull(t);
                })
                .catch((error) => {
                  console.error("Error loading PayPalAPICallManager:", error);
                });
            } else {
              import("../../API/StripeAPICallManager")
                .then((module) => {
                  const StripeAPICallManager = module.default;
                  StripeAPICallManager.checkStripeOnboardedSuccessfull(t);
                })
                .catch((error) => {
                  console.error("Error loading StripeAPICallManager:", error);
                });
            }
          } else {
            console.log("createUser");
            import("../../API/APICallManager")
              .then((module) => {
                const m = module.default;
                m.createUser(user, REACT_APP_STANDARD_PAYMENT_PROVIDER);
              })
              .catch((error) => {
                console.error("Error loading APICallManager:", error);
              });
          }
        });
      });
    } else {
      setLoading(false);
    }
  }
  useEffect(() => {
    // Check if already any cookies are set, if yes set the timer to 0, otherwise to 4000
    // This is important, cause in the meantime, where its not checked if the user is
    // logged in, the landing page is shown. Calling checkForOnboarded() at the beginning
    // will cause a 4 to 8 seconds delay in renderin the landing page, which is not acceptable.
    // But if the cookies are set, i know that the user might be logged in and i have to check. The site is
    // also in the cache, so in this case it wont affect the initial loading time of the website.
    let cookiesSet = false;
    if (Cookies.get("cookie_consent")) {
      cookiesSet = true;
    }
    if (cookiesSet) {
      checkForOnboarded();
    } else {
      setLoading(false);
      const timer = setTimeout(() => {
        checkForOnboarded();
      }, 5000);
      return () => clearTimeout(timer);
    } // This will clear the timer when the component unmounts
  }, []);
  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  };
  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "50px",
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading ? (
        <Suspense
          fallback={
            <div style={style}>
              <CircularProgress />
            </div>
          }
        >
          {children}
        </Suspense>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "50px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {Cookies.get("cookie_consent") ? null : <CookieBanner />}
    </AuthContext.Provider>
  );
}
