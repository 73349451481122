import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// translations for ar, de, en, es, fr, he, hi, it, ja, ko, pt, ru, th, vi, zh
import arTranslations from './locales/ar/translation.json';
import deTranslations from './locales/de/translation.json';
import enTranslations from './locales/en/translation.json';
import esTranslations from './locales/es/translation.json';
import frTranslations from './locales/fr/translation.json';
import heTranslations from './locales/he/translation.json';
import hiTranslations from './locales/hi/translation.json';
import itTranslations from './locales/it/translation.json';
import jaTranslations from './locales/ja/translation.json';
import koTranslations from './locales/ko/translation.json';
import ptTranslations from './locales/pt/translation.json';
import ruTranslations from './locales/ru/translation.json';
import thTranslations from './locales/th/translation.json';
import viTranslations from './locales/vi/translation.json';
import zhTranslations from './locales/zh/translation.json';

// import other translations...

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ar: {
        translation: arTranslations
      },
      de: {
        translation: deTranslations
      },
      en: {
        translation: enTranslations
      },
      es: {
        translation: esTranslations
      },
      fr: {
        translation: frTranslations
      },
      he: {
        translation: heTranslations
      },
      hi: {
        translation: hiTranslations
      },
      it: {
        translation: itTranslations
      },
      ja: {
        translation: jaTranslations
      },
      ko: {
        translation: koTranslations
      },
      pt: {
        translation: ptTranslations
      },
      ru: {
        translation: ruTranslations
      },
      th: {
        translation: thTranslations
      },
      vi: {
        translation: viTranslations
      },
      zh: {
        translation: zhTranslations
      },
      
      // other translations...
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator'],
    },
  });

export default i18n;