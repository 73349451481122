// SharedValuesContext.js
import { createContext, useContext, useState, useRef, useEffect } from "react";

let app;
let firebase;
let storage;

const getFirebase = async () => {
  if (!firebase) {
    const FirebaseModule = await import("firebase/compat/app");
    const StorageModule = await import("firebase/compat/storage");
    const AppModule = await import("../firebase");

    firebase = FirebaseModule.default;
    storage = StorageModule.default;
    app = AppModule.default;
  }
  return { app, firebase, storage };
};
const SharedValuesContext = createContext();

export const SharedValuesProvider = ({ children }) => {
  const [shouldGetImages, setShouldGetImages] = useState(false);
  const lastSessionID = useRef("");
  const [userId, setUserId] = useState(undefined);
  const checkForUserId = async () => {
    if (!userId) {
      const returnobject = await getFirebase().then(({ app, firebase, storage }) => {
        if (app.auth().currentUser && userId === undefined) {
          let userId = app.auth().currentUser?.uid;
          if (userId) {
            setUserId(userId);
          }
          return userId;
        }
      });
      return returnobject;
    }
  };
  const [sharedValues, setSharedValues] = useState({
    sessions: [],
    user: {},
    uploadViewPageSelectedPhotographer: "",
    photographers: [],
  });
  const getPhotographers = async () => {
    const photographers = await getFirebase().then(
      async ({ app, firebase, storage }) => {
        const db = firebase.firestore();
        const photographers = [];
        let currentUserId;
        if (!userId) {
          currentUserId = await checkForUserId();
          if (!currentUserId) return;
        } else {
          currentUserId = userId;
        }
        // It musnt have the deleted attribute
        const qrCodeTimeLinesRef = db
          .collection("users")
          .doc(currentUserId)
          .collection("qrCodeTimelines")
          .where("deleted", "==", false)
          .where("name", "!=", "");
        await qrCodeTimeLinesRef.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data().name !== "") {
              photographers.push({
                id: doc.id,
                name: doc.data().name,
              });
            }
          });
        });
        setSharedValues((prevValues) => ({
          ...prevValues,
          photographers: photographers,
        }));
        return photographers;
      }
    );
    return photographers;
  };

  const getImagesOfSession = async (
    sessionID,
    imagesToFetch,
    completlyReload = false
  ) => {
    let currentUserId;
    if (!userId) {
      currentUserId = await checkForUserId();
      if (!currentUserId) return;
    } else {
      currentUserId = userId;
    }
    try {
      let resultImages = await getFirebase().then(
        async ({ app, firebase, storage }) => {
          const db = firebase.firestore();
          let resultImages = [];

          // fetch the images in batches of 100
          // only 100 at a time, since firebase has a limit of documents per batch
          for (let i = 0; i < imagesToFetch.length; i += 100) {
            let end = Math.min(i + 100, imagesToFetch.length);
            let batch = imagesToFetch.slice(i, end);

            let promises = batch.map((image) =>
              db
                .collection("users")
                .doc(currentUserId)
                .collection("images")
                .doc(image.docName)
                .get()
            );

            let batchImages = await Promise.all(promises).then((docs) =>
              docs.filter((doc) => doc.exists).map((doc) => doc.data())
            );

            resultImages.push(...batchImages);
          }
          let resultSession = sharedValues.sessions.find(
            (session) => session.qrCodeIdentifier === sessionID
          );
          if (completlyReload) {
            resultSession.images = resultImages;
            // Filter all images that are in deletedImages from the resultImages
            if (resultSession.deletedImages) {
              resultSession.images = resultSession.images.filter(
                (image) =>
                  !resultSession.deletedImages.some(
                    (deletedImage) =>
                      deletedImage.timeUploaded.toString() ===
                        image.timeUploaded.toString() &&
                      deletedImage.docName === image.docName
                  )
              );
            }
          } else {
            // if resultSession.images contains resultimages[i] then replace it,
            // if not then push it
            for (let i = 0; i < resultImages.length; i++) {
              let index = resultSession.images.findIndex(
                (image) => image.docName === resultImages[i].docName
              );
              if (index !== -1) {
                resultSession.images[index] = resultImages[i];
              } else {
                resultSession.images.push(resultImages[i]);
              }
            }
          }
          // sort the images of the resultSession by date
          resultSession.images.sort(function (a, b) {
            return a.date.toDate() - b.date.toDate();
          });
          setSharedValues((prevValues) => ({
            ...prevValues,
            sessions: prevValues.sessions.map((session) => {
              if (session) {
                return session.qrCodeIdentifier === sessionID
                  ? resultSession
                  : session;
              }
            }),
          }));
          return resultImages;
        }
      );
      return resultImages;
    } catch (error) {
      console.log("Error getting images: ", error);
    }
  };
  const removeImage = async (sessionID, imageID) => {
    setSharedValues((prevValues) => ({
      ...prevValues,
      sessions: prevValues.sessions.map((session) => {
        if (session.qrCodeIdentifier === sessionID) {
          const beforeLength = session.images.length;
          const filteredImages = session.images.filter(
            (image) => image.docName !== imageID
          );
          const afterLength = filteredImages.length;
          console.log(
            `Before length: ${beforeLength}, After length: ${afterLength}`
          );
          return {
            ...session,
            images: filteredImages,
            deletedImages: [
              ...(session.deletedImages || []),
              session.images.find((image) => image.docName === imageID),
            ],
          };
        } else {
          return session;
        }
      }),
    }));
  };
  const refreshSession = async (sessionID, completlyReload = false) => {
    let currentUserId;
    if (!userId) {
      currentUserId = await checkForUserId();
      if (!currentUserId) return;
    } else {
      currentUserId = userId;
    }
    getFirebase().then(async ({ app, firebase, storage }) => {
      const db = firebase.firestore();
      const sessionRef = db
        .collection("users")
        .doc(currentUserId)
        .collection("sessions")
        .doc(sessionID);
      const sessionDoc = await sessionRef.get().then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          console.log("No such document!");
        }
      });
      if (sessionDoc) {
        // Replace the session in sharedValues with the new session
        setSharedValues((prevValues) => ({
          ...prevValues,
          sessions: prevValues.sessions.map((session) =>
            session.qrCodeIdentifier === sessionID
              ? // add deletedImages to the sessionDoc if the current has it
                session.deletedImages
                ? { ...sessionDoc, deletedImages: session.deletedImages }
                : sessionDoc
              : session
          ),
        }));

        // Set shouldGetImages to true to indicate that getImagesOfSession should be called
        setShouldGetImages({ sessionID, sessionDoc, completlyReload });
      }
    });
  };
  useEffect(() => {
    if (shouldGetImages) {
      getImagesOfSession(
        shouldGetImages.sessionID,
        shouldGetImages.sessionDoc.images,
        shouldGetImages.completlyReload
      );
      setShouldGetImages(false); // Reset shouldGetImages to false
    }
  }, [shouldGetImages]);

  const currentlyFetchingSessions = useRef(false);
  const restartAfterFinished = useRef(false);
  const currentlyNoImages = useRef(false);
  const getAllSessions = async (photographer, fromBeginning) => {
    if (currentlyFetchingSessions.current) {
      restartAfterFinished.current = true;
      return { noImages: currentlyNoImages.current };
    } else {
      currentlyFetchingSessions.current = true;
    }
    const returnObject = getFirebase().then(async ({ app, firebase, storage }) => {
      let noImages = true;
      
      if (fromBeginning) {
        lastSessionID.current = "";
      }
      if (sharedValues.allSessionsFetched && !fromBeginning) {
        currentlyFetchingSessions.current = false;
        return { noImages: currentlyNoImages.current };
      }
      let currentUserId;
      if (!userId) {
        currentUserId = await checkForUserId();
        if (!currentUserId){
          currentlyFetchingSessions.current = false;
          return;
        }
      } else {
        currentUserId = userId;
      }
      const db = firebase.firestore();
      try {
        if (!lastSessionID.current) {
          // Fetch the unassignable session
          const unassignable = await db
            .collection("users")
            .doc(currentUserId)
            .collection("sessions")
            .where("qrCodeIdentifier", "==", "unassignable")
            .get();

          let unassignableDoc;
          unassignable?.forEach((doc) => {
            unassignableDoc = doc.data();
          });
          // if the unassignable session contains the photographer, set noImages to false
          if (
            unassignableDoc &&
            (unassignableDoc?.photographers?.includes(photographer) ||
              (!photographer && unassignableDoc?.images?.length > 0))
          ) {
            noImages = false;
          }

          if (unassignableDoc) {
            setSharedValues((prevValues) => ({
              ...prevValues,
              sessions: [unassignableDoc],
            }));
          }
        }
        let ref = db
          .collection("users")
          .doc(currentUserId)
          .collection("sessions")
          .orderBy("timeFirstQrCodeTaken", "desc")
          .limit(6);
        if (lastSessionID.current) {
          ref = ref.startAfter(lastSessionID.current);
        }

        if (photographer) {
          ref = ref.where("photographers", "array-contains", photographer);
        }

        // get all sessions from the sessions collection of the user
        await ref.get().then((querySnapshot) => {
          const docs = [];
          querySnapshot.forEach((doc) => {
            const docData = doc.data();
            if (docData.qrCodeIdentifier !== "unassignable") {
              docs.push(docData);
            }
          });
          if (docs.length !== 0) {
            if (!photographer) {
              // move the last document to the first position
              docs.unshift(docs.pop()); //this is the unassigned images session
            }
            // Merge the new sessions with the old ones
            setSharedValues((prevValues) => {
              const result = [...prevValues.sessions, ...docs];
              // Order the result by timeFirstQrCodeTaken
              result.sort(function (a, b) {
                return b.timeFirstQrCodeTaken - a.timeFirstQrCodeTaken;
              });
              // Move the unassignable session to the first position
              const unassignableIndex = result.findIndex(
                (session) => session.qrCodeIdentifier === "unassignable"
              );
              if (unassignableIndex !== -1) {
                result.unshift(result.splice(unassignableIndex, 1)[0]);
              }
              return {
                ...prevValues,
                sessions: result,
                allSessionsFetched: false,
              };
            });

            lastSessionID.current =
              querySnapshot.docs[querySnapshot.docs.length - 1];
            if (docs.length > 0) {
              noImages = false;
            }
            currentlyNoImages.current = noImages;
            return { noImages: noImages };
          } else {
            setSharedValues((prevValues) => ({
              ...prevValues,
              allSessionsFetched: true,
            }));
          }
        });
        currentlyFetchingSessions.current = false;
        if (restartAfterFinished.current) {
          restartAfterFinished.current = false;
          getAllSessions(photographer);
        }
        currentlyNoImages.current = noImages;
        return { noImages: noImages };
      } catch (error) {
        currentlyFetchingSessions.current = false;
        console.log("Error getting documents: ", error);
      }
    });
    return returnObject;
  };
  const removeSession = async (sessionID) => {
    setSharedValues((prevValues) => ({
      ...prevValues,
      sessions: prevValues.sessions.filter(
        (session) => session.qrCodeIdentifier !== sessionID
      ),
    }));
  };

  const getUserDoc = async (force = true) => {
    getFirebase().then(async ({ app, firebase, storage }) => {
      let currentUserId;
      if (!userId) {
        currentUserId = await checkForUserId();
        if (!currentUserId) return;
      } else {
        currentUserId = userId;
      }
      const db = firebase.firestore();
      if (force || !sharedValues.user) {
        await db
          .collection("users")
          .doc(currentUserId)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setSharedValues((prevValues) => ({
                ...prevValues,
                user: doc.data(),
              }));
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      }
    });
  };
  const updateUpload = (id, newValue) => {
    setSharedValues((prevValues) => {
      const uploadExists = prevValues.uploads.some(
        (upload) => upload.id === id
      );

      if (uploadExists) {
        return {
          ...prevValues,
          uploads: prevValues.uploads.map((upload) =>
            upload.id === id ? { ...upload, ...newValue } : upload
          ),
        };
      } else {
        return {
          ...prevValues,
          uploads: [...prevValues.uploads, { id, ...newValue }],
        };
      }
    });
  };

  const updateSharedValue = (key, value) => {
    setSharedValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  function startTimer(duration, callback) {
    setTimeout(callback, duration);
  }
  const contextValue = {
    sharedValues,
    getPhotographers,
    updateSharedValue,
    updateUpload,
    refreshSession,
    getImagesOfSession,
    getAllSessions,
    getUserDoc,
    removeSession,
    removeImage,
  };

  return (
    <SharedValuesContext.Provider value={contextValue}>
      {children}
    </SharedValuesContext.Provider>
  );
};

export const useSharedValues = () => {
  return useContext(SharedValuesContext);
};
