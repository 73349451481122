import ReactDOM from 'react-dom/client';
import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import TagManager from 'react-gtm-module'; //google Tag manager for google tracking
import ReactGA from "react-ga4";
const tagManagerArgs = {
  gtmId: 'AW-11392717305',
}
TagManager.initialize(tagManagerArgs)
//const TRACKING_ID = "G-ZRXYBL3SM5"; // OUR_TRACKING_ID
const TRACKING_ID = "G-M53LF4RKFR"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
<App />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();