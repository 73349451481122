import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { SharedValuesProvider } from "./API/SharedValuesProvider.js";
import { AuthProvider } from "./Components/Authentication/AuthContext.js";
import { UploadSharedValuesProvider } from "./Components/UploadSharedValuesProvider.js";
import CircularProgress from "@mui/material/CircularProgress";
import PrivateRoute from "./Components/Authentication/PrivateRoute.js";
import i18n from "./i18n.js";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
// The following imports are lazy loaded
const NavigationBar = lazy(() => import("./Components/NavBar.js"));
const Footer = lazy(() => import("./Components/Footer.js"));
const UploadOverlay = lazy(() => import("./Components/UploadOverlay/UploadOverlay.js"));
const SessionViewPage = lazy(() => import("./Components/CustomerPages/SessionViewPage"));
const Signup = lazy(() => import("./Components/Authentication/SignUp.js"));
const ForgotPassword = lazy(() => import("./Components/Authentication/ForgotPassword"));
const Login = lazy(() => import("./Components/Authentication/Login.js"));
const UploadViewImagesPage = lazy(() => import("./Components/UploadPage/UploadViewImagesPage.js"));
const UpdateProfile = lazy(() => import("./Components/Authentication/UpdateProfile"));
const GetQrCodes = lazy(() => import("./Components/GetQrCodes/GetQrCodes"));
const SettingsPage = lazy(() => import("./Components/Settings/SettingsPage"));
const PrivacyPolicyPage = lazy(() => import("./Components/LegalPages/PrivacyPolicyPage.js"));
const TermsAndConditionsPage = lazy(() => import("./Components/LegalPages/TermsAndConditionsPage"));
const CookiePolicyPage = lazy(() => import("./Components/LegalPages/CookiePolicyPage"));
const StripeOnboardedPage = lazy(() => import("./Components/StripeOnboardedPage/StripeOnboardedPage.js"));
const PerformancePage = lazy(() => import("./Components/PerformancePage/PerformancePage"));
const StartPage = lazy(() => import("./Components/StartPage/StartPage"));
const WelcomePage = lazy(() => import("./Components/WelcomePage/WelcomePage"));
const PurchasedImagesPage = lazy(() => import("./Components/PurchasedImagesPage/PurchasedImagesPage"));
const FileNotFoundPage = lazy(() => import("./Components/404Page/FileNotFoundPage"));
const Imprint = lazy(() => import("./Components/LegalPages/Imprint"));
const AdminPage = lazy(() => import("./Components/AdminPage/AdminPage.js"));
const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "50px",
};

function App() {
  return (
    <Suspense
      fallback={
        <div style={style}>
          <CircularProgress />
        </div>
      }
    >
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <div style={{ flex: "1 0 auto", minHeight: "calc(100vh)" }}>
            <ToastContainer style={{ zIndex: 999999 }} />
            <SharedValuesProvider>
              <AuthProvider>
                <UploadSharedValuesProvider>
                  <NavigationBar />
                  <UploadOverlay />
                  <Suspense
                    fallback={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "50px",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    }
                  >
                    <Routes>
                    <Route
                path="/s/:sessionID/:justBoughtImages?"
                element={<SessionViewPage />}
              />
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/login/:updateProfile" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/upload-images"
                element={<PrivateRoute component={UploadViewImagesPage} />}
              />
              <Route
                path="/update-profile"
                element={<PrivateRoute component={UpdateProfile} />}
              />
              <Route
                path="/get-qrcodes"
                element={<PrivateRoute component={GetQrCodes} />}
              />
              <Route
                path="/performance"
                element={<PrivateRoute component={PerformancePage} />}
              />
              <Route
                path="/settings"
                element={<PrivateRoute component={SettingsPage} />}
              />
              <Route
                path="/purchased-images"
                element={<PrivateRoute component={PurchasedImagesPage} />}
              />
              <Route
                path="/onboarded"
                element={<PrivateRoute component={StripeOnboardedPage} />}
              />
              <Route
                path="/home"
                element={<PrivateRoute component={WelcomePage} />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditionsPage />}
              />
              <Route path="/cookie-policy" element={<CookiePolicyPage />} />
              <Route path="/imprint" element={<Imprint />} />
              <Route exact path="/fixStartPage" element={<StartPage />} />
              <Route exact path="/" element={<StartPage />} />
              <Route exact path="/admin" element={<AdminPage />} />
              <Route exact path="*" element={<FileNotFoundPage />} />                    </Routes>
                  </Suspense>
                </UploadSharedValuesProvider>
              </AuthProvider>
            </SharedValuesProvider>
          </div>
          <Footer />
        </BrowserRouter>

      </I18nextProvider>
    </Suspense>
  );
}

export default App;
